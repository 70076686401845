form {
    display: flex;
    align-items: center;
    flex-direction: column;
}

div label {
    display: flex;
    flex-direction: row;
    align-content: center;
    gap: 5px;
}
form div {
    display: flex;
    flex-direction: column;
    gap: 10px;
    max-width: 300px;
}

input[type=text] {
    width: 250px;
}

.button-span {
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    gap: 30px;
}

.file-span {
    display: flex;
    flex-direction: row;
    padding-top: 25px;
	color: rgb(190 190 190);
}
.signature-span {
    text-align: left;
    display: flex;
    align-items: flex-start;
    flex-direction: row-reverse;
    gap: 10px;
}

.reset-button{
    background-color: hsla(0, 100%, 50%, 0.5);
    color: white;
}

.submit-button{
    background-color: hsla(110, 100%, 76%, 0.5);
    color: white;
}
